/* ==========================================================================
   Plugins
   ========================================================================== */

/* Date Range Picker */
.daterangepicker{

  .drp-calendar{
    max-width: inherit;
  }

  .calendar-table th,
  .calendar-table td {
    width: 44px;
    height: 32px;

    &.active,
    &.active:hover {
      background-color: $cor-primaria;
    }

    &.off{
      background-color: transparent;
    }
  }

  &::after{
    top: -5px;
  }

  &::before{
    top: -6px;
  }

  &.show-calendar .drp-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn{
      padding: 0 15px;
    }

  }

}


/* CK Editor */
.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content .ck-toolbar{
  background: #F8F8F8 !important;
  border-color: #C9C9C9 !important;
  border-radius: 5px 5px 0 0 !important;
}

.ck-editor__editable_inline{
  min-height: 250px;
  background: #F8F8F8 !important;
  border-color: #C9C9C9 !important;
  border-top: none !important;
  border-radius: 0 0 5px 5px !important;
}

/* Select 2 */

.select2-container--default .select2-selection--single{
  height: 60px;
  background-color: #f8f8f8;
  border-color: #c9c9c9;
  border-radius: 5px;

  .select2-selection__rendered{
    line-height: 58px;
  }

  .select2-selection__arrow{
    height: 100%;
  }
}

.select2-container.select2-container--default.select2-container--open{
  .select2-search--dropdown .select2-search__field{
    height: 42px;
    border-radius: 5px;
  }
}

.select2-container--default .select2-selection--multiple{
  min-height: 60px;
  background-color: #f8f8f8;
  border-color: #c9c9c9;
  border-radius: 5px;

  .select2-selection__choice{
    height: 47px;
    line-height: 47px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: $cor-primaria;
    font-size: 18px;
    color: white;
    border-color: darken($cor-primaria, 10%);
    border-radius: 5px;
  }

  .select2-selection__choice__remove{
    margin-right: 10px;
    color: inherit;
    transition: all ease 0.6s;

    &:hover{
      color: white;
      opacity: 0.7;
    }
  }
}