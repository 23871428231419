/* ==========================================================================
   Botoes
   ========================================================================== */

.btn,
a.btn{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 15px;
  font-size: 20px;
  line-height: 46px;
  font-weight: 500;
  text-transform: lowercase;
  border-radius: 5px;
  transition: all ease 0.6s;

  &-input{
    height: 58px;
  }

  &-lg{
    font-size: 24px;
    height: 64px;
    line-height: 62px;
  }

  &-sm{
    font-size: 18px;
    height: 40px;
    line-height: 38px;
  }

  &-roxo{
    background-color: $cor-primaria;
    color: white;

    &:hover{
      background-color: lighten($cor-primaria, 15%);
      color: white;
    }
  }

  &-azul{
    background-color: #1b61ff;
    color: white;

    &:hover{
      background-color: lighten(#1b61ff, 15%);
      color: white;
    }
  }

  &-verde{
    background-color: #1db321;
    color: white;

    &:hover{
      background-color: lighten(#1db321, 15%);
      color: white;
    }
  }

  &-border {
    border: 1px solid $cor-primaria;
    color: $cor-primaria;

    &:hover {
      background-color: $cor-primaria;
      border-color: $cor-primaria;
      color: white;
    }
  }

  &-radius{
    border-radius: 32px;
  }

  &-close {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    background-color: #dc3545;
    border: none;
    border-radius: 50%;

    &:hover {
      background-color: darken(#dc3545, 10%);
    }

    &::before {
      @extend .fas;
      @extend .fa-times;
      content: '\f00d';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      color: white;
    }
  }
}