/* ==========================================================================
   Login
   ========================================================================== */

.login{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-bg{
    background: rgb(145,57,207);
    background: -moz-linear-gradient(129deg, rgba(145,57,207,1) 0%, rgba(97,12,156,1) 100%);
    background: -webkit-linear-gradient(129deg, rgba(145,57,207,1) 0%, rgba(97,12,156,1) 100%);
    background: linear-gradient(129deg, rgba(145,57,207,1) 0%, rgba(97,12,156,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9139cf",endColorstr="#610c9c",GradientType=1);
  }

  .logo-copyright{
    display: block;
    margin: 40px auto 0;

    @include media-min768{
      position: absolute;
      bottom: 40px;
      right: 40px;
    }
  }

  &-form{
    margin: auto;
    width: 90%;
    max-width: 500px;
    padding: 40px 30px 35px;
    background-color: white;
    text-align: center;
    border-radius: 10px;

    @include media-min768{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .form-control + .form-control {
      margin-top: 15px;
    }

    .btn{
      margin-top: 25px;
    }

    &-titulo{
      margin-bottom: 30px;
      font-weight: 500;
      font-size: 28px;
      color: #292929;

      @include media-min768{
        font-size: 36px;
      }
    }
  }
}