/* ==========================================================================
   Geral
   ========================================================================== */


body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #292929;
  background-color: #fff;
}

.titulo{
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 32px;
  font-weight: 400;
}

.subtitulo{
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 400;
}

.label{
  color: #717171;
  font-size: 16px;
  margin-bottom: 8px;

  &-hidden{
    visibility: hidden;
  }

  &-divisor{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 100%;
  }

}

.form-control{
  background-color: #F8F8F8;
  height: 60px;
  font-size: 20px;
  border: 1px solid #C9C9C9;
  border-radius: 5px;
}

textarea.form-control{
  min-height: 160px;
}

.main-wrapper{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100vh;
}

.corpo{
  padding: 50px 40px;
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
}

.table{

  thead{
    th{
      font-size: 16px;
      font-weight: 400;
      color: #717171;
    }
  }

  tbody{

    td{
      font-size: 18px;
      color: #242424;
    }
  }
}

.link-underline{
  position: relative;
  font-weight: 400;

  &::after{
    content: '';
    position: absolute;
    bottom: 1px;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #232323;
    transition: inherit;
  }

  &:hover{

    &::after{
      width: 0;
    }
  }
}

.link-primario{
  color: $cor-primaria;

  &:hover{
    color: lighten($cor-primaria, 10%);
  }

  &::after{
    background-color: $cor-primaria;
  }
}

.lista-imagens{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .list-item{

    & + .list-item{
      margin-left: 15px;
    }

    &-imagem{
      position: relative;
      width: 128px;
      height: 128px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 5px;
      cursor: pointer;
      overflow: hidden;
      transition: all ease 0.6s;

      &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $cor-primaria;
        opacity: 0;
        z-index: 1;
        transition: inherit;
      }

      &::after{
        content: '\f06e';
        position: absolute;
        top: 45%;
        left: 50%;
        font-family: 'Font Awesome 5 Free';
        font-size: 26px;
        color: white;
        transform: translate(-50%, -50%);
        opacity: 0;
        z-index: 2;
        transition: inherit;
      }

      &:hover{

        &::before{
          opacity: 1;
        }

        &::after{
          top: 50%;
          opacity: 1;
        }
      }
    }
  }
}

.checkbox {
  &-input {
    display: none;
  }

  &-label {
    position: relative;
    display: block;
    width: 18px;
    height: 18px;
    background-color: #F8F8F8;
    border: 1px solid #C9C9C9;
    border-radius: 3px;
    transition: all 0.3s ease;

    &::before {
      @extend .fas;
      @extend .fa-check;
      content: '\f00c';
      visibility: hidden;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      color: white; 
      transition: all 0.4s ease;
    }
  }

  &-input:checked + &-label {
    background-color: $cor-primaria;
    border-color: darken($cor-primaria, 20%);

    &::before {
      visibility: visible;
    }
  }
}

.table-arquivo {
  
  &-box {
    display: inline-block;
    width: 64px;
    height: 64px;
    background-color: $cor-primaria;
    border-radius: 5px;
  }
  
  &-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &-doc {
    position: relative;
    &::before {
      @extend .far;
      @extend .fa-file;
      content: '\f15b';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      color: white; 
    }
  }

  &-info {
    display: inline-block;
    margin-left: 16px;
  }

  &-nome {
    margin: 0;
    color: $cor-primaria;
    font-size: 18px;
  }

  &-formato {
    margin: 0;
    color: #717171;
    font-size: 12px;
  }

  &-btn {
    display: inline-block;
    background: none;
    border: none;
    outline: none;
    font-size: 12px;
    color: $cor-primaria;
    text-decoration: underline;

    &:hover {
      color: lighten($cor-primaria, 20%);
    }
  }
}

.paginacao {
  display: flex;
  justify-content: flex-end;

  &-conteudo {
    display: flex;
    align-items: center;
    list-style: none;

    li {
      margin: 0 2px;

      .btn {
        margin: 0 6px;
        height: 40px;
      }
    }

    span {
      margin: 0 2px;
    }
  }
}

.midia {
  background: transparent;
  text-align: center;
  padding: 80px 40px;
  border: 4px dashed $cor-primaria;

  .dz-message{
    margin: 0;
  }

  .btn{
    margin: auto;
  }
}

.custom-file-padrao .custom-file-input{
  cursor: pointer;
}

.custom-file-padrao .custom-file-label{
  background-color: #F8F8F8;
  border-color: #C9C9C9;
}

.label-btn-roxo::after{
  content: 'Selecionar';
  background-color: $cor-primaria;
  color: white;
  transition: all ease 0.6s;
}

.custom-file-padrao .custom-file-input:hover + .label-btn-roxo::after,
.custom-file-padrao .custom-file-input:hover + .label-btn-roxo::after{
  background-color: lighten($cor-primaria, 10%);
}

.custom-file-padrao.file-lg,
.custom-file-padrao.file-lg .custom-file-label,
.custom-file-padrao.file-lg .custom-file-label::after,
.custom-file-padrao.file-lg .custom-file-input{
  height: 60px;
  font-size: 20px;
  line-height: 46px;
}

.custom-file-padrao.file-lg .custom-file-label::after{
  height: calc(60px - 2px);
}