// Css raiz do projeto

/*!
 * Publicador
 * Desenvolvido por Fattoriaweb em: 12/02/2020
 */

// Libs
@import "../sassLibs/bootstrap/bootstrap";
@import "../sassLibs/fontawesome/fontawesome";
@import "../sassLibs/fontawesome/brands";
@import "../sassLibs/fontawesome/regular";
@import "../sassLibs/fontawesome/solid";
@import "../sassLibs/daterangepicker.css";

// Config
@import "../sass/config/_variaveis.scss";

// Tipografia
@import "../sass/_tipografia.scss";

// Geral
@import "../sass/geral/_reset.scss";
@import "../sass/geral/_medias.scss";
@import "../sass/geral/_geral.scss";

// Componentes
@import "../sass/componentes/_btns.scss";
@import "../sass/componentes/_modais.scss";
@import "../sass/componentes/_nav.scss";
@import "../sass/componentes/_plugins.scss";

// Paginas
@import "paginas/login";
