/* ==========================================================================
   Navegações
   ========================================================================== */

.nav-lateral{
  width: 350px;
  height: 100%;
  padding: 0 40px;
  color: white;
  background-color: $cor-primaria;

  .logo{
    display: block;
    margin: 88px auto 71px;
    width: 194px;
  }

  .titulo-bem-vindo{
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 32px;
    font-weight: 300;
  }

  .sair{
    position: relative;
    font-weight: 400;
    transition: all ease 0.3s;

    &::after{
      content: '';
      position: absolute;
      left: 0;
      bottom: 2px;
      display: block;
      width: 100%;
      height: 3px;
      background-color: white;
      transition: inherit;
    }

    &:hover,
    &:focus{
      color: white;
      opacity: 0.7;

      &::after{
        width: 0;
      }
    }
  }

  .nav{

    .nav-list{
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-item{

      & + .nav-item{
        margin-top: 20px;
      }
    }

    &-link{
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px 0;
      height: 40px;
      font-size: 22px;
      background-color: transparent;
      border-radius: 5px;
      transition: all ease 0.6s;

      &:hover,
      &:focus,
      &.active{
        padding-left: 15px;
        color: white;
        background-color: #500088;
      }
    }

    &-submenu{
      display: none;
      margin-top: 12px;
      padding-left: 30px;
    }

    &-subitem{

      & + .nav-subitem{
        margin-top: 10px;
      }
    }

    &-sublink{
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;
      height: 32px;
      font-size: 18px;
      background-color: transparent;
      border-radius: 5px;
      transition: all ease 0.6s;

      &:hover,
      &:focus,
      &.active{
        padding-left: 10px;
        color: white;
        background-color: #500088;
      }
    }
  }
}