/* ==========================================================================
   Modais
   ========================================================================== */

.modal{

  .modal-dialog{
    max-width: 800px;
  }

  &-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 32px 32px 17px;
    border: none;

    .btn + .btn {
      margin-left: 4px;
    }

    .fas{
      line-height: 32px;
    }
  }

  &-body{
    padding: 0 32px 32px;
  }

  &-title{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px;
  }

  .detalhes-midia{

    &_img{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 16px;
      width: 100%;
      height: 300px;
      background-color: #DEDEDE;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 5px;
    }

    .btn + .btn{
      margin-top: 16px;
    }

    &_info{
      margin-top: 16px;
      font-size: 14px;
      color: #242424;
    }
  }
}