/* ==========================================================================
   Reset
   ========================================================================== */

html,
body { min-height: 100vh; }

a{
  color: inherit;
  transition: all ease 0.3s;

  &:hover { text-decoration: inherit; }
}

img, iframe, object, video {
  max-width: 100%;
  width: auto;
  height: auto;
}

form {
  &:after,
  &:before {
    display: table;
    content: " ";
  }
  &:after { clear: both; }
}

textarea {
  max-width: 100%;
  width: 100%;
}

b {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
